<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区域 -->
      <!-- <div class="avatar_box">
      <img src="../assets/logo.png" alt="">
      </div> -->
      <!-- 登录表单区域 -->
      <div class="title">梁先生的茶后台管理</div>
      <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" prefix-icon="iconfont icon-user" ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
           <el-input v-model="loginForm.password" prefix-icon="iconfont icon-3702mima" :show-password="true" type="password" ></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 这是登录表单的数据绑定对象
      user:'',
      paw:'',
      loginForm: {
        username: 'admin',
        password: '123456',
      },
      // 这是表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' },
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
  	    this.enterSearch()
  },
    // 销毁enter事件
    destroyed () {
      window.removeEventListener('keydown', this.keyDown, false)
    },
  methods: {
    // 点击重置按钮，重置登录表单
    resetLoginForm() {

      this.$refs.loginFormRef.resetFields()
	  this.loginForm.username=''
	  this.loginForm.password=''
    },
	   // 回车确认登陆
	    enterSearch(e){
	
	      document.onkeydown = e => {
	
	        if (e.keyCode === 13 && e.target.baseURI.match(/login/)) {
	          //回车后执行搜索方法
	          this.login()
	        }
	      }
	
	    },

async login() {
  if (!this.loginForm.username || !this.loginForm.password) {
    // 如果用户名或密码为空，提示错误信息
    this.$message.error('用户名和密码不能为空');
    return; // 终止函数执行
  }

try {
  const { data: res } = await this.$http.get("/adminLogin/signIn", {
    params: {
      tel: this.loginForm.username,
      paw: this.loginForm.password
    }
  });

  if (res && res.code === 200) {
    localStorage.setItem('username', res.data.username);
    localStorage.setItem('isLoggedIn', true);
    this.$message.success('登录成功！');
  
    this.$router.push('/home');
  } else {
    switch (res.code) {
      case 500:
        throw new Error('用户密码错误');
      case 0:
        throw new Error('该用户不是管理员');
      default:
        throw new Error('未知错误发生');
    }
  }
} catch (error) {
  // 在这里处理捕获到的错误
  console.error('捕获到错误：', error.message);
  // 进行相应的错误处理逻辑，例如显示错误提示信息
  this.$message.error(error.message);
}


},

	
  }
}
</script>

<style lang="less" scoped>
.login_container {
  width: 100%;
  height: 100%;
  /*如果想做背景图片 可以给标签一个class 直接添加背景图*/
  position: relative;
  background: url(../assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.login-bg{
   width: 100%;
    height: 100%;
    background: #3E3E3E;
}

.login_box {
  width: 400px;
  height: 270px;
  background: hsla(0,0%,100%,.3);
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 24px;
    color: white;
    border-bottom: 1px solid #ffffff;
  }
  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-end;
}
</style>
